<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item v-if="!viewType" label="车牌号：">
					<el-autocomplete
						v-model.trim="formInline.licenseNumber"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
						@select="handleSelect"
					></el-autocomplete>
				</el-form-item>
				<el-form-item v-else label="设备名称：">
					<el-autocomplete
						v-model.trim="formInline.name"
						:fetch-suggestions="querySearchAsync"
						placeholder="请输入内容"
						@select="handleSelect"
					></el-autocomplete>
				</el-form-item>
				<el-form-item :label="viewType ? '设备类型：' : '车型：'">
					<el-select v-model="formInline.modelDictionaryItemId" placeholder="请选择">
						<el-option v-for="(item, index) in modelDictionaryList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="!viewType" label="用途：">
					<el-select v-model="formInline.usageDictionaryItemId" placeholder="请选择">
						<el-option v-for="(item, index) in usageDictionaryList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-else label="性质：">
					<el-select v-model="formInline.characterDictionaryItemId" placeholder="请选择">
						<el-option v-for="(item, index) in characterDictionaryList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="viewType ? tableHead : carTableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<!-- 插槽操作栏固定属性operation -->
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView ref="createView" :isShowDetail="isShowDetail" :viewType="viewType" @getList="getList" />
	</div>
</template>

<script>
export default {
	name: 'carManagement',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			formInline: {
				name: '',
				licenseNumber: '',
				modelDictionaryItemId: null,
				usageDictionaryItemId: null,
				characterDictionaryItemId: null,
			},
			modelDictionaryList: [], //车型
			usageDictionaryList: [], // 用途
			characterDictionaryList: [], // 性质
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			carTableHead: [
				{
					label: '车牌号',
					prop: 'licenseNumber',
					formatter: (row) => {
						return row.licenseNumber || '-';
					},
				},
				{
					label: '车型',
					prop: 'modelDictionaryItem',
					formatter: (row) => {
						return row.modelDictionaryItem?.title || '-';
					},
				},
				{
					label: '性质',
					prop: 'characterDictionaryItem',
					formatter: (row) => {
						return row.characterDictionaryItem?.title || '-';
					},
				},
				{
					label: '用途',
					prop: 'usageDictionaryItem',
					formatter: (row) => {
						return row.usageDictionaryItem?.title || '-';
					},
				},
				{
					label: '载人数',
					prop: 'carryingCapacity',
					formatter: (row) => {
						return row.carryingCapacity || '-';
					},
				},
				{
					label: '所属单位',
					prop: 'ownerOrganizationName',
					formatter: (row) => {
						return row.ownerOrganizationName || '-';
					},
				},
				{
					label: '负责人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '车辆状态',
					prop: 'state',
					formatter: (row) => {
						return row.state === 'I' ? '闲置' : row.state === 'U' ? '使用中' : '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			filterLoading: false,
			isShowDetail: false,
			options: [],
			link: '',
		};
	},
	props: {
		viewType: {
			type: String,
			default: '',
		},
		tableHead: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		if (this.viewType == 'deviceManagement') {
			this.getCharacterList();
		} else {
			this.getCarUseList();
		}
		this.getCarTypeList();
		this.getList();
	},
	methods: {
		// 获取车型list
		getCarTypeList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, {
					params: { dictionaryCode: this.viewType ? 'EQUIPMENT_TYPE' : 'MOTORCYCLE_TYPE', size: 9999, current: 1 },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.modelDictionaryList = res.data.collection || [];
						this.modelDictionaryList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取车辆用途
		getCarUseList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'VEHICLE_USAGE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.usageDictionaryList = res.data.collection || [];
						this.usageDictionaryList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取性质list
		getCharacterList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'EQUIPMENT_PROPERTY', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.characterDictionaryList = res.data.collection || [];
						this.characterDictionaryList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		handleSelect(subInput) {
			this.formInline.licenseNumber = subInput.licenseNumber;
		},
		changeOpen(row) {
			console.log(row, '---row');
			let url = row._links.patch.href;
			this.$http
				.patch(url, { enabled: row.enabled })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		//获取列表
		getList(filterType = '') {
			filterType == 'filter' ? '' : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			let url = this.viewType ? this.api['Devices#index'].href : this.api['Vehicles#index'].href;
			this.$http
				.get(url, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (filterType == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: this.viewType ? item.name : item.licenseNumber,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.link = res.data._links.create.href || '';
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.seeMore {
	color: #0d99ff;
}
</style>
